import E from 'wangeditor';
import emotions from './emotions.config';
import { Message } from "element-ui";

class wEditor {
    content;
    constructor(ele, url,fileName = 'file',menus = [], fn = false, paste = false) {
        this.editor = new E(ele);
        this.uploadUrl = url;
        this.editor.customConfig.uploadImgMaxLength = 30;
        this.editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024;
        this.editor.customConfig.uploadImgServer = this.uploadUrl;
        //添加表情
        this.editor.customConfig.emotions = emotions;
        this.editor.customConfig.uploadImgHeaders = {
            'Authorization': localStorage.getItem('token')
        }
        this.editor.customConfig.uploadFileName = fileName;
        if (fn) {
            this.editor.customConfig.onchange = fn;
        }
        //对复制粘贴的文本的格式进行判断
        if (paste) {
            let _this = this;
            this.editor.customConfig.pasteTextHandle = function (content) {
                /*如果直接从word文档直接复制过来的需要正则过滤操作WordXML格式*/
                if (/urn:schemas-microsoft-com:office:office/.test(content)) {
                    Message({
                        type: 'warning',
                        message: '请将word文档的内容复制到txt格式的文件中，再从txt文件中复制到客服答题的输入框中，谢谢',
                        showClose: true,
                        duration: 5000
                    });
                    return '';
                } else {
                    return content;

                }
            }

        }

        this.editor.customConfig.zIndex = 100;
        this.editor.customConfig.uploadImgHooks = {
            before: function (xhr, editor, files) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size / 1024 / 1024 > 3) {
                        return {
                            prevent: true,
                            msg: '上传图片大小不能超过 3MB!'
                        }
                    }
                }
            },
        }
        if (menus.length) {
            this.editor.customConfig.menus = menus;
        } else {
            this.editor.customConfig.menus = [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'italic',  // 斜体
                'underline',  // 下划线
                'strikeThrough',  // 删除线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                'link',  // 插入链接
                'list',  // 列表
                'justify',  // 对齐方式
                'quote',  // 引用
                'emoticon',  // 表情
                'image',  // 插入图片
            ]
        }

        this.editor.create();
    }
    getWEHtml() {
        return this.editor.txt.html();
    }

    //发送完消息，清空文本域内容
    getNull() {
        return this.editor.txt.clear();
    }

    getWEText() {
        return this.editor.txt.text();
    }

    setWEHtml(val) {
        this.editor.txt.html(val)
    }
    getPasteTxt() {
        return this.content;
    }
}

export default wEditor;